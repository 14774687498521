<template>
    <div class="office-chairs">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Sillas para oficina',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricamos y comercializamos sillas para oficinas' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Sillas para oficina'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricamos y comercializamos sillas para oficinas.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Sillas para oficina' },
			{name: 'twitter:description', content: 'Fabricamos y comercializamos sillas para oficinas.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Sillas para oficina' },
			{itemprop: 'description', content: 'Fabricamos y comercializamos sillas para oficinas.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    metaInfo() {
        return {
            title: this.page_title,
        }
    },
    data() {
        return {
            title_page: 'Sillas para Oficina',
            data_products: {
                title_page: 'Sillas para oficina',
                types: [
                    { text: 'Sillas Presidenciales', value: 'sillas-presidenciales' },
                    { text: 'Sillas Gerenciales', value: 'sillas-gerenciales' },
                    { text: 'Sillas Operativas', value: 'sillas-operativas' },
                    { text: 'Sillas Interlocutoras', value: 'sillas-interlocutoras' },
                    { text: 'Sillas para Colectivos', value: 'sillas-para-colectivos' }
                ],
                products_image_full: true,
                products: [
                    // PRESIDENCIALES
                    {
                        name: 'Silla presidencial Ergohuman',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/ergohuman.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/ergohuman.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Atenas',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/atenas.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/atenas.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Japón',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/japon.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/japon.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Lion',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/lion.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/lion.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Londres',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/londres.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/londres.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Manhatan',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/manhatan.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/manhatan.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Milan',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/milan.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/milan.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Monaco',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/monaco.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/monaco.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Ofis',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/ofis.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/ofis.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Plays',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/plays.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/plays.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Rumania',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/rumania.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/rumania.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Sidney',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/sidney.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/sidney.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Singapur',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/singapur.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/singapur.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    {
                        name: 'Silla presidencial Suecia',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/presidenciales/suecia.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/presidenciales/suecia.jpg'
                        ],
                        tags: ["sillas-presidenciales"]
                    },
                    // GERENCIALES
                    {
                        name: 'Silla gerencial Canadá',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/canada.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/canada.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Checa',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/checa.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/checa.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Kayro',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/kayro.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/kayro.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Lion',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/lion.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/lion.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Monaco',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/monaco.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/monaco.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Play',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/play.jpg',
                        slides: [
                             'mobiliario-de-oficina/sillas/productos/gerenciales/play.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Roma',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/roma.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/roma.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Rumania',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/rumania.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/rumania.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Seul',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/seul.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/seul.jpg',
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    {
                        name: 'Silla gerencial Tokyo',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/gerenciales/tokyo.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/gerenciales/tokyo.jpg'
                        ],
                        tags: ["sillas-gerenciales"]                       
                    },
                    // INTERLOCUTORAS
                    {
                        name: 'Silla interlocutora Ankara',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/ankara.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/ankara.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_1.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_2.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_3.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora 4',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_4.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/interlocutora_4.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Isoceles',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/isoceles.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/isoceles.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Isoceles Malla',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/isoceles_malla.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/isoceles_malla.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Italia',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/italia.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/italia.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Novaiso',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/novaiso.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/novaiso.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Orion',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/orion.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/orion.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora Prisma',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/prisma.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/prisma.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    {
                        name: 'Silla interlocutora San Valentín',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/interlocutoras/san_valentin.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/interlocutoras/san_valentin.jpg'
                        ],
                        tags: ["sillas-interlocutoras"]                       
                    },
                    // OPERATIVOS
                    {
                        name: 'Silla operativa Atlanta',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/atlanta.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/atlanta.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },       
                    {
                        name: 'Silla operativa 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_1.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },  
                    {
                        name: 'Silla operativa 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_2.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },     
                    {
                        name: 'Silla operativa 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/ejecutiva_3.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },
                    {
                        name: 'Silla operativa Escocia',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/escocia.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/escocia.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    }, 
                    {
                        name: 'Silla operativa Irlanda',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/irlanda.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/irlanda.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },
                    {
                        name: 'Silla operativa Job',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/job.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/job.jpg',
                        ],
                        tags: ["sillas-operativas"]                       
                    }, 
                    {
                        name: 'Silla operativa Livorno',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/livorno.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/livorno.jpg'
                        ],
                        tags: ["sillas-operativas"]                       
                    },
                    {
                        name: 'Silla operativa Yacarta',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/operativas/yacarta.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/operativas/yacarta.jpg',
                        ],
                        tags: ["sillas-operativas"]                       
                    },
                    // COLECTIVOS    
                    {
                        name: 'Sillas Tandem Tapizado 4 puestos',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tabdem_tapizado_4_puestos.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tabdem_tapizado_4_puestos.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },      
                    {
                        name: 'Sillas Tandem Isoceles Plus',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tamden_isoceles_plus.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tamden_isoceles_plus.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    }, 
                    {
                        name: 'Sillas Tandem 2 puestos',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_2_puestos.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_2_puestos.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },
                    {
                        name: 'Sillas Tandem Dublin',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_dublim.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_dublim.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    }, 
                    {
                        name: 'Sillas Tandem Metálico 3 puestos',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_3_puestos.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_3_puestos.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },  
                    {
                        name: 'Sillas Tandem Metálico 4 puestos',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_4_puestos.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_4_puestos.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },  
                    {
                        name: 'Sillas Tandem Metálico Tapizado',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_tapizado.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_metalico_tapizado.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },
                    {
                        name: 'Sillas Tandem Tapizado 2 puestos',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/sillas/productos/colectivos/tandem_tapizado_2_puestos.jpg',
                        slides: [
                            'mobiliario-de-oficina/sillas/productos/colectivos/tandem_tapizado_2_puestos.jpg'
                        ],
                        tags: ["sillas-para-colectivos"]                       
                    },   
                ]
            }
        }
    },
    computed: {
        url_tag() {
            return this.$route.params.tag;
        }
    },
    watch: {
        url_tag() {
            this.page_title = this.url_tag.replaceAll('-', ' ').capitalize();
        }
    }
}
</script>